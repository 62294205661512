
import { defineComponent, watch, reactive, ref } from "vue";
import moment, { Moment } from "moment";
import { VerticalAlignBottomOutlined } from "@ant-design/icons-vue";
import useTableHeight from "@/hooks/useTableHeight";

import Pagination from "@/components/Pagination.vue";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { s3Download } from "@/utils/s3Download";
import { getSpeicalUsageList } from "@/API/rv/rvRetailData";
import { RetailData, RetailDataType } from "./types";
import { message } from "ant-design-vue";
import RetailDataImportContent from "@/views/RetailValidation/RetailData/components/RetailDataImportContent.vue";
import batchInputModel from "@/views/UserCar/Components/batchInputModel.vue";
import downloadFile from '@/utils/payment/downloadFile';
type Key = ColumnProps["key"];
const columns = [
  {
    title: "Filename",
    dataIndex: "fileName",
    key: "filename",
    width: 200,
    ellipsis: true,
  },
  {
    title: "VIN Count",
    dataIndex: "vinCount",
    key: "vinCount",
    width: 160,
  },
  {
    title: "Import Time",
    dataIndex: "importTime",
    key: "importTime",
    slots: { customRender: "importTime" },
    width: 220,
  },
  {
    title: "Operation",
    dataIndex: "operation",
    key: "operation",
    slots: { customRender: "operation" },
    width: 160,
  },
  {
    title: "USERID",
    dataIndex: "userName",
    key: "userName",
    width: 160,
  },
];

export default defineComponent({
  name: "retailData",
  components: {
    RetailDataImportContent,
    Pagination,
    VerticalAlignBottomOutlined,
    batchInputModel
  },
  setup() {
    const { tableHeight } = useTableHeight();
    const retailDataType = ref<RetailDataType>("special");
    const rowSelection = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: RetailData[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: RetailData[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
    });

    const fileName = ref("");
    const vinCount = ref("");
    //const importTime = ref("");
    const mode = ref<string[]>(["date", "date"]);
    const startMonth = ref<any>("");
    const endMonth = ref<any>("");
    const vinNo = ref('');
    const vinNoArr = ref<string[]>([]);
    const vinBatchInputVisible = ref(false);
    const retailDate = ref<string[]>([]);
    const wholesaleDate = ref<string[]>([]);
    const pagination = reactive({
      pageSize: 20,
      page: 1,
      total: 0,
    });

    const data = ref([]);

    const handlePickerChange = (val: Moment[]) => {
      //console.log(val);
      startMonth.value = val[0];
      endMonth.value = val[1];
    };
    const handlePickerPanelChange = (val: Moment[], mode: string[]) => {
      startMonth.value = val[0];
      endMonth.value = val[1];
      mode = [
        mode[0] === "date" ? "month" : mode[0],
        mode[1] === "date" ? "month" : mode[1],
      ];
    };

    const handleResetClick = (): void => {
      fileName.value = "";
      vinCount.value = "";
      startMonth.value = "";
      endMonth.value = "";
      //importTime.value = "";
    };

    const resetTableSelectData = (): void => {
      rowSelection.selectedRowKeys = [];
      rowSelection.selectedRowData = [];
    };

    const handleSearch = () => {
      resetTableSelectData();
      const params = {
        fileName: fileName.value,
        vinCount: vinCount.value,
        //importTime: importTime.value,
        startMonth: startMonth.value,
        endMonth: endMonth.value,
        page: pagination.page,
        pageSize: pagination.pageSize,
      };
      getSpeicalUsageList({ params }).then((res: any) => {
        console.log("res", res);
        data.value = res.content;
        pagination.total = res.totalCount;
      });
    };
    handleSearch();
    //分页事件处理
    const handlerPaginationChange = (page: number): void => {
      pagination.page = page;
      handleSearch();
    };

    const handlerShowSizeChange = (current: number, pageSize: number): void => {
      pagination.pageSize = pageSize;
      Object.assign(pagination, { page: current, pageSize: pageSize });
      handleSearch();
    };

    const handleDownloadClick = (docId: string) => {
      s3Download({
        url: `/saleapi/import/download/${docId}`,
        method: "get",
      }).then((res) => {
        console.log(res);
      });
    };

    const handleBatchDownload = () => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error("Please select Retail Data");
        return;
      }
      s3Download({
        url: `/saleapi/import/zip/download`,
        method: "post",
        data: {
          docList: rowSelection.selectedRowData.map((item) => ({
            docId: item.docId,
          })),
        },
      }).then((res) => {
        console.log(res);
      });
    };

    const visible = ref(false);
    const handleImportClick = (): void => {
      visible.value = !visible.value;
    };

    const handleExportDetail = () => {
      // s3Download({
      //   url: '/saleapi/exportDetail',
      //   method: "post",
      //   data: {
      //     importDateBegin: startMonth.value,
      //     importDateEnd: endMonth.value,
      //     vinLists: vinNoArr.value ? vinNoArr.value : [ vinNo.value ? [vinNo.value] : []],
      //     retailDateBegin: retailDate.value?.[0] ?? '',
      //     retailDateEnd: retailDate.value?.[1] ?? '',
      //     wholesaleDateBegin: wholesaleDate.value?.[0] ?? '',
      //     wholesaleDateEnd: wholesaleDate.value?.[0] ?? ''
      //   },
      // })
      const params = {
        url: '/saleapi/exportDetail',
        method: "post",
        params: {
          importDateBegin: startMonth.value,
          importDateEnd: endMonth.value,
          vinLists: vinNoArr.value.length > 0 ? vinNoArr.value : vinNo.value ? [vinNo.value] : [],
          retailDateBegin: retailDate.value?.[0] ?? '',
          retailDateEnd: retailDate.value?.[1] ?? '',
          wholesaleDateBegin: wholesaleDate.value?.[0] ?? '',
          wholesaleDateEnd: wholesaleDate.value?.[0] ?? ''
        },
      };
      downloadFile(params).catch((msg) => message.warning(msg));
    }

    watch(vinNoArr, (values) => {
      vinNo.value = values.length ? values[0] : "";
    });

    return {
      fileName,
      vinCount,
      //importTime,
      mode,
      startMonth,
      endMonth,
      vinNo,
      vinNoArr,
      vinBatchInputVisible,
      retailDate,
      wholesaleDate,
      columns,
      tableHeight,
      pagination,
      moment,
      data,
      rowSelection,
      handlePickerPanelChange,
      handlePickerChange,
      handleResetClick,
      handleSearch,
      handlerPaginationChange,
      handlerShowSizeChange,
      handleDownloadClick,
      handleBatchDownload,
      handleImportClick,
      visible,
      retailDataType,
      handleExportDetail
    };
  },
});
